import React, { useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { CiCircleRemove } from "react-icons/ci";

import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  useEffect(() => {
    if (toggleMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [toggleMenu]);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.serpico} alt="diner logo" />
      </div>
      <ul className="app__navbar-links">
        {["Home", "Menu", "Information"].map((link) => (
          <li className="p__opensans" key={link}>
            <a href={`#${link.toLowerCase()}`} onClick={() => setToggleMenu(false)}>
              {link}
            </a>
          </li>
        ))}
      </ul>
      <div className="app__navbar-order">
        <a href="https://www.doordash.com/store/the-old-serpico-syracuse-939212/">
          <img src={images.doordash} alt="doordash logo" />
          <span>DoorDash</span>
        </a>
        <div />
        <a href="https://www.grubhub.com/restaurant/the-old-serpicos-diner-512-state-fair-blvd-syracuse/1429331">
          <img src={images.grubhub} alt="grubhub logo" />
          <span>Grubhub</span>
        </a>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />

        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <CiCircleRemove
              fontSize={27}
              color="#ffffff"
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              {["Home", "Menu", "Information"].map((link) => (
                <li className="p__opensans" key={link}>
                  <a
                    href={`#${link.toLowerCase()}`}
                    onClick={() => setToggleMenu(false)}
                  >
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
