import Swal from 'sweetalert2';

export const WelcomeAlert = () => {
  Swal.fire({
    title: "<strong>We're Hiring!</strong>",
    icon: "info",
    html: `
      <p>We’re looking for a <strong>waitress</strong> and a <strong>kitchen helper</strong> to join our team!</p>
      <p>Please send your resume to <a href="mailto:theoldserpico@josephcarmosino.com">resume@josephcarmosino.com</a>, and also your availability.</p>
    `,
    showCloseButton: true,
    focusConfirm: false,
    confirmButtonText: `<i class="fa fa-thumbs-up"></i> Got it`,
    confirmButtonAriaLabel: "Close!",
    willOpen: () => {
      document.body.style.overflow = 'hidden';
    },
    willClose: () => {
      document.body.style.overflow = 'auto';
    },
  });
};

export default WelcomeAlert;
